import axios from "axios";
import {
  AppAdtopiaToken,
  AppPostCodeSingleApi,
  AppEmailValidateSingleApi,
  AppSinglePointPhoneValidationURL,
} from "../Constants/EnvConstants";
require("dotenv").config();

const base_api_url = process.env.REACT_APP_API_URL;
const api_auth_token = process.env.REACT_APP_API_AUTH_TOKEN;

export const Api = axios.create({
  baseURL: base_api_url,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + api_auth_token,
  },
});

export const CommonEndApi = axios.create({
  baseURL: AppPostCodeSingleApi,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + AppAdtopiaToken,
  },
});

export const CommonEmailValidateApi = axios.create({
  baseURL: AppEmailValidateSingleApi,
  timeout: 4000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + AppAdtopiaToken,
  },
});

export const CommonPhoneValidation = axios.create({
  baseURL: AppSinglePointPhoneValidationURL,
  timeout: 4000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + AppAdtopiaToken,
  },
});
