import { CommonPhoneValidation } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";

// export const usePhoneValidation = () => {
//   const phoneValidation = async (phone) => {
//     const getValidation = await Api.get(
//       "v1/get-phone-validation?phone=" + phone,
//       {}
//     );

//     return getValidation;
//   };
//   return { phoneValidation };
// };

export const usePhoneValidation = () => {
  const phoneValidation = async (phone) => {
    const response = await CommonPhoneValidation.post("/api/validate", {
      ProjectCode: EnvConstants.AppAdtopiaUPID,
      Environment: EnvConstants.AppEnv,
      Phone: phone,
    });

    return response;
  };
  return { phoneValidation };
};
