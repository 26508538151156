require("dotenv").config();

const AppDomain = process.env.REACT_APP_DOMAIN;
const AppTitle = process.env.REACT_APP_TITLE;
const AppEnv = process.env.REACT_APP_ENV;
const AppDomainWithoutProtocol = process.env.REACT_APP_DOMAIN_WITHOUT_PROTOCOL
  ? process.env.REACT_APP_DOMAIN_WITHOUT_PROTOCOL
  : window.location.hostname;
const AppLpDefaultUrl = process.env.REACT_APP_LP_DEFAULT_URL;
const AppAdtopiaUPID = process.env.REACT_APP_ADTOPIA_UPID;
const AppReferrerURL = document.referrer;
const AppLpRedirectDefaultUrl = process.env.REACT_APP_LP_REDIRECT_DEFAULT_URL;
const AppApDefaultUrl = process.env.REACT_APP_AP_DEFAULT_URL;
const AppApiUrl = process.env.REACT_APP_API_URL;
const AppAdtopiaToken = process.env.REACT_APP_ADTOPIA_TOKEN;
const AppPostCodeSingleApi = process.env.REACT_APP_POSTCODE_LOOKUP_API;
const AppEmailValidateSingleApi = process.env.REACT_APP_EMAIL_VALIDATE_API;
const AppSinglePointPhoneValidationURL =
  process.env.REACT_APP_SINGLE_POINT_PHONE_VALIDATION_API;

console.log("AppDomain WithoutProtocol  : = " + AppDomainWithoutProtocol);

export {
  AppDomain,
  AppTitle,
  AppEnv,
  AppDomainWithoutProtocol,
  AppLpDefaultUrl,
  AppAdtopiaUPID,
  AppReferrerURL,
  AppLpRedirectDefaultUrl,
  AppApDefaultUrl,
  AppApiUrl,
  AppAdtopiaToken,
  AppPostCodeSingleApi,
  AppEmailValidateSingleApi,
  AppSinglePointPhoneValidationURL,
};
